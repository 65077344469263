#place {
  display: block;
  margin-bottom: 2%;
  
  /* height: 100vh; */
}

/*.a-box {
  /background: #f8f4f2;
    font-family: Arial;
    margin:5px; 
  }
  */

/* .place {
  background-color: #D9D9D9;
  padding: 17px;
  border: 1px solid white;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 60px;
} */



.a-box {
  display: inline-block;
  /* width: 240px; */
  text-align: center;
  margin-top: 5%;
  /* width: 98%; */
  
}

.img-container {
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  background-color: rgb(175, 175, 175);
}

.img-container img {
  height: 150px;
  object-fit: cover;

}
.postulates-year{
  text-align: center;
}
.postulates-year-row{
  margin-top: 2%;
  
}
.btn-postulates {
    border: none;
    font-size: 11px;
    background-color: rgb(222, 226, 231);
    padding: 8px 29px;
    border-radius: 30px;
}
.btn-postulates:hover {
  background-color: rgba(222, 226, 231, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.001);
    color: rgb(0, 0, 0);
}
.postulates-card{
  text-align: center;
  margin: 1px 0px;
}
.but {
  border: none;
  font: 10px;
  background-color: rgb(222, 226, 231);
  padding: 6px 28px;
  border-radius: 30px;
}

.but:hover{
  box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}
.card-read-more{
  text-align: center;
}


.text-container {
  padding: 85px 20px 20px 20px;
  border-radius: 20px;
  /* background-color: #D9D9D9; */
  background-color: rgba(222, 226, 231, 0.5);
  margin: -90px 0px 0px 0px;
  line-height: 20px;
  font-size: 12px;
  border-bottom: 2px solid #505050;

  text-align: center;
}

.text-container h3 {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.text-container h4 {
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width:820px){
  .a-box{
    /* width: 180px; */
  }
  .img-container{
    /* height: 100px;
    width: 100px; */
  }
}