.blog {
    padding: 1%;
    margin: 1%;
    background-color: rgba(222, 226, 231, 0.5);
    /* background-color: #D9D9D9; */
    text-align: justify;
    /* height: 100vh; */
    border-radius: 10px;
    font-size: 13px;
}

.blog img {
    border-radius: 15px;
    border: .5px solid #a3a3a3;
}

.blog-head {
    margin-top: 100px;
}

.blog-head h2 {
    margin-bottom: 0;
    font-size: 60px;
    /* font-weight: 500; */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.blog-head small {
    font-size: 20px;
}

.blog-dp {
    background-color: #000;
}

.blog-row {
    /* height: 20vh; */
    padding: 1%;
}

.blog-dp {
    height: 300px;
    width: 100%;
}
.blog-dp-Miska{
    height: 100%;
    width: 300px;
}

.blog-image-right {
    float: right;
    height: 300px;
    width: 300px;
    margin-left: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.blog-image-left {
    float: left;
    height: 300px;
    width: 300px;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.resume-link {
    display: inline-block;
    background-color: whitesmoke;
    color: black;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
}

.resume-link:hover {
    background-color: white;
}

@media screen and (max-width: 767px) {
    .blog-head {
        text-align: center;
    }

    .blog-image-right,
    .blog-image-left {
        float: none;
        margin: 0 auto;
        display: block;
        height: 250px;
        width: 90%;
        margin-bottom: 10px;
    }

    .blog-row {
        padding: 5px;
    }
}
