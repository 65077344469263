.team-container {
  padding: 90px 35px 20px 34px;
  border-radius: 20px;
  background-color: rgba(222,226,231,0.5);
  margin: -90px 0px 0px 0px;
  line-height: 20px;
  font-size: 12px;
  border-bottom: 2px solid #505050;
  text-align: left;
}

/*.secy-img {
 opacity: 0; 
}*/

.team-container h3 {
  /* font-weight: bold; */
  font-size: 14px;
  text-align: center;
  margin: 10px 0px;
}

.team-top {
  display: flex;
}
.mobile .team-container {
text-align: center;
}

.mobile .team-container h2 {
color: black;
}
.mobile {
  display: none;
}

.team-social-icons {
padding-left: 0;
margin-bottom: 0;
list-style: none;
padding-top: 10px;
text-align: center;
}
.team-social-icons li {
display: inline-block;
margin-bottom: 4px;
}
.team-social-icons li.title {
margin-right: 15px;
text-transform: uppercase;
color: white;
font-weight: 700;
font-size: 13px;
}
.team-social-icons a {
font-size: 12px;
background-color: rgba(222,226,231,0.5);
font-size: 16px;
display: inline-block;
line-height: 34px;
width: 34px;
height: 34px;
text-align: center;
margin-right: 8px;
border-radius: 100%;
-webkit-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
transition: all 0.2s linear;
}

@media screen and (max-width: 758px) {
  .mobile {
      display: block;
  }
  .phone {
      display: none;
  }
}

/* Add these new rules */
.img-container img {
width: 100%;
height: 100%;
object-fit: cover;        /* Ensure the image covers the container */
object-position: center;  /* Center the image within the container */
}
