#StudentSec{
    display: block;
}
/* .stud-flex{
    display: flex;
    justify-content: space-between;
} */

.stu{
    background-color: rgba(222,226,231,.5);
    padding: 25px;
    border-radius: 25px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 13px;

    font-family: Poppins,sans-serif;
}
.stud{
    background-color: rgba(222, 226, 231, 0.5);
    border-bottom: 2px solid #505050;
    padding: 25px;
    margin-top: 15px;
    transition: 0.3s ease;
    border-radius: 20px;
    font-size: 12px;
    margin-bottom: 20px;
    font-family: Poppins,sans-serif;
    /* width:48%; */
}

.stud:hover{
    box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.Btn{
    margin-left: 30%;
    border: none;
    font-size: 10px;
    background-color: rgb(222, 226, 231);
    padding: 6px 28px;
    border-radius: 30px;
    float:right;
}
.Btn:hover{
    background-color: rgba(222, 226, 231, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.001);
    color: rgb(0, 0, 0);
    
}

@media screen and (max-width: 768px) {
    .Btn{
        margin-left: -70%;
        margin-top: 35px;
    }
    .stud{
        width: 80%;
    }
    #student p{
        text-align: justify;
    }
    
}
#student-col{
    text-align: center;
     height: 80vh;
} 
.Container:after {
    content: "";
    display: table;
    clear: both;
}