#home {
    display: block;
}

.slider-content img {
    height: 100%;
    width: 100%;
}

#img {
    border-radius: 5%;
    height: 400px;
}

.slider-parent {
    margin-top: 1%;
    text-align: center;
    margin-bottom: 1%;
}
.slider-image-container{
    text-align: center;
}
#r1 {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

#r2 {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    text-align: right;
}

.slider-content {
    text-align: justify;
    background-color: rgba(222, 226, 231, 0.5);
    border-radius: 20px;
    transition: opacity 1s;
    /* border-bottom: 2px solid #505050; */
}

#slider-heading {
    padding-top: 30px;
    font-size: 2.2rem;

}

.slider-content .row {
    padding: 0.8% 4%;

}

.sl {
    padding: 1.2% 0.5%;

}

/* activities */
#p,
#f {
    margin-top: 0%;
    font-size: 1.6rem;
}

#p {
    margin-right: 5%;
}

#f {
    margin-left: 5%;
}

#p,
#f:hover {
    cursor: pointer;
}

.indicators {
    margin: 1.2%;
}

.indicators:hover {
    cursor: pointer;
}

/* messages */
#P,
#F {
    margin-top: 0%;
    font-size: 1.6rem;
}

#P {
    margin-right: 5%;
}

#F {
    margin-left: 5%;
}

#P,
#F:hover {
    cursor: pointer;
}

.Indicators {
    margin: 1.2%;
}

.Indicators:hover {
    cursor: pointer;
}

.read-more {
    text-align: center;
}

.mess {
    margin-top: 30px;
}


#read-more {
    margin-top: 5%;
    background-color: rgba(222, 226, 231, .5);
    margin-bottom: 2%;
    border: none;
    color: black;
}

#read-more:hover {
    background-color: rgba(222, 226, 231, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.001);
    color: rgb(0, 0, 0);
}

#slider-date {
    text-align: right;
}

#recent {
    width: fit-content;
    text-align: left;
    /* margin-left: 7%; */
    margin-top: 0%;
    margin-bottom: 1%;
    margin-left: 12px;
}

/* Message CSS */
.mes {
    background-color: rgba(222, 226, 231, .5);
    padding: 20px;
    border-radius: 25px;
    margin-top: 20px;
    font-size: 13px;
    margin-bottom: 15px;
    font-family: Poppins, sans-serif;
}

.mes p {
    margin: 0;
}

.getm h5 {
    margin: 0;
}

.getm {
    text-align: center;
    align-items: center;
    background-color: rgba(222, 226, 231, .5);
    /* background-color: #edebeb; */
    padding: 10px 39px;
    width: 88%;
    margin-left: 5.5rem;
    border-radius: 25px;
}

.getm h5 {
    font-size: 1.15rem;
}

.mybtn {
    border: none;
    font-size: 12px;
    background-color: rgb(222, 226, 231);
    padding: 6px 28px;
    border-radius: 30px;
    float: right;
    margin-left: 30%;
}

a {
    color: #000000;
    text-decoration: none;
}

li {
    list-style: none;
}

a:hover {

    color: #000000;
}

.white {
    background-color: #fff;
    height: 400px;
    border-radius: 25px;
}

.readmore {
    text-align: right;
}

.circles {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .getm {
        padding: 10px 12px;
        width: 100%;
        margin-left: 0.2rem;
        border-radius: 20px;
    }
    .getm h5 {
        font-size: 1.0rem;
    }

    .slider-content {
        font-size: 12px;
    }

    #slider-heading {
        padding-top: 30px;
        font-size: 1.5rem;

    }

    #slider-date {
        font-size: 15px;
    }

    #img {
        display: none;
        /* height: 300px;
        width: 90%; */
    }
    .slider-content{
        height: 72vh;
    }

    .slider-content .row {
        padding: 0.1% 4%;
    }

    .mybtn{
        float: none;
        margin-top: 10px;
        margin-left: 0%;
    }
}