*{
  padding:0;
  margin:0;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
  font-family: Poppins,sans-serif;
  
}



