#menubar {
  /* background-color: #fff !important; */
  background-color: rgb(255, 255, 255) !important;
  /* padding: 1%; */
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

/* .nav-item.dropdown:hover .dropdown-menu {
  display: block;
} */

/* .nav-link,
.dropdown-item {
  text-align: center !important;
} */

/* .sticky-nav {
    position: sticky;
    top: 0;
  } */

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand>img {
  padding: 7px 14px;
}

#soce-logo {
  height: 60px;
  width: 225px;
}