#Events{
    display: block;
    /* height: 100vh; */
}
.eve{
    background-color: rgba(222,226,231,.5);
    padding: 25px;
    border-radius: 25px;
    margin-top: 20px;
    font-size: 13px;
    margin-bottom: 25px;
    font-family: Poppins,sans-serif;
}
.fre{
    background-color: rgba(222, 226, 231, 0.5);
    border-bottom: 2px solid #505050;
    padding: 25px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
    /* margin-left: 15px;
    float: left;*/
    /* width:80%; */
    transition: 0.3s ease;
    font-size: 12px;

}
.fre:hover{
    box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}
.far{
    background-color: rgba(222, 226, 231, 0.5);
    border-bottom: 2px solid #505050;
    padding: 25px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
    /* margin-right: 15px;
    float: right;*/
    /* width:80%; */
    font-size: 12px;

    transition: 0.3s ease;
}
.far:hover{
    box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}
.event-btn{
    /* margin-left: 30%; */
    border: none;
    font-size: 10px;
    background-color: rgb(222, 226, 231);
    padding: 6px 28px;
    border-radius: 30px;
    /* float:right; */
}
.event-btn:hover{
    background-color: rgba(222, 226, 231, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.001);
    color: rgb(0, 0, 0);
    
}
@media screen and (max-width: 768px) {
    .coll{
        margin-left: -70%;
        margin-top: 35px;
    }
    .fre,.far{
        width: 80%;
    }
    
}
#Event-col{
    text-align: center;
    /* height: 80vh; */
}
.container:after {
    content: "";
    display: table;
    clear: both;
}