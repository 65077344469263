.str {
    background-color: rgba(222, 226, 231, 0.5);
    padding: 25px;
    border-radius: 25px;
    margin-top: 20px;
    font-size: 13px;
    margin-bottom: 25px;
    font-family: Poppins, sans-serif;
}

.str-row h1 {
    margin: 20px 0px;
    font-size: 24px;
}

.str-row {
    text-align: center;
}

.str-card {
    background-color: rgba(222, 226, 231, 0.5);
    border-bottom: 2px solid #505050;
    padding: 25px;
    margin-top: 15px;
    transition: 0.3s ease;
    border-radius: 20px;
    font-size: 12px;
    margin-bottom: 20px;
    font-family: Poppins, sans-serif;
    width: 30%;
}

.ptr {
    background-color: rgba(222, 226, 231, 0.5);
    border-bottom: 2px solid #505050;
    padding: 25px;
    margin-top: 15px;
    transition: 0.3s ease;
    border-radius: 20px;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    width: 30%;
}

.str-card:hover {
    box-shadow: 1.5px 1.5px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.str-btn {
    border: none;
    font-size: 10px;
    background-color: rgb(222, 226, 231);
    padding: 6px 20px;
    margin-top: 2%;
    border-radius: 30px;
}

.str-btn:hover {
    background-color: rgba(222, 226, 231, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.001);
    color: rgb(0, 0, 0);
}

.str-btn1 {
    border: none;
    font-size: 10px;
    background-color: rgb(222, 226, 231);
    padding: 6px 20px;
    border-radius: 30px;
}

.popup {
    background: #fff;
    height: 400px;
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.18);
    transition: 0.5s ease;
    border-radius: 15px;
    padding: 10px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.popup-header h1 {
    font-size: 24px;
}

.popup-header img {
    cursor: pointer;
    width: 20px;
}

.popup-content {
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .str-card {
        text-align: center;
        width: 80%;
    }

    .popup {
        width: 80%;
    }
}